import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY, IS_FROM_PRODUCT_DETAILS_PAGE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { fetchResultPageProducts, resetResultPageProducts } from '@lib/core/service/slices/kioskRootSlice';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { removeUrlParams } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { ResultPageSkeleton } from '@components/web/src/organisms/Skeleton/ResultPageSkeleton/ResultPageSkeleton';
import { ResultPage } from '@components/web/src/templates/ResultPages/Kiosk/ResultPage/ResultPage';

const ResultContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { locale, productCategory } = useApp();

  const { isUserCharactersLoading, isUserDataLoading, userBestMatchCharacterForCurrentServiceProductCategory } =
    useUser();
  const { isFidelityCardLoading, isFidelityCardScanned } = useFidelityCard();

  const { retailerStoreType } = useRetailerLocation();
  const { isEnableKioskFidelityScanAddon } = useAddons();
  const { retailerFidelityCardImage } = useRetailer();
  const { products, isProductsLoading } = useKioskRoot();
  const isFromProductDetailsPage = !!searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE);

  const [showContent, setShowContent] = useState(isFromProductDetailsPage);

  const navigateToProductCatalogPage = () => {
    navigate(prependBasename(PAGES.vinhood.catalog) + (searchParams.toString() ? `?${searchParams.toString()}` : ''));
  };

  useEffect(() => {
    if (!isFromProductDetailsPage) {
      dispatch(
        fetchResultPageProducts({
          [GPRL_CHARACTER_QUERY]: userBestMatchCharacterForCurrentServiceProductCategory?.characterId,
          [PRODUCT_CATEGORY_QUERY]: productCategory,
          offset: 0,
        }),
      );
    } else {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE],
      });
    }

    return () => {
      if (!window.location.pathname.includes(PAGES.vinhood.product)) {
        dispatch(resetResultPageProducts());
      }
    };
  }, [userBestMatchCharacterForCurrentServiceProductCategory?.characterId]);

  useEffect(() => {
    const isDataLoading = isFidelityCardLoading || isProductsLoading || isUserDataLoading || isUserCharactersLoading;

    setTimeout(() => {
      setShowContent(!isDataLoading);
    }, 2000);
  }, [isFidelityCardLoading, isUserDataLoading, isProductsLoading, isUserCharactersLoading]);

  useEffect(() => {
    if (userBestMatchCharacterForCurrentServiceProductCategory) {
      MixpanelTracker.events.resultPageView([userBestMatchCharacterForCurrentServiceProductCategory]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [userBestMatchCharacterForCurrentServiceProductCategory]);

  const { parseProductsArray } = useParsedProducts();

  return (
    <>
      {showContent ? (
        <ResultPage
          shouldHideFeedback
          shouldHideShare
          isEnableKioskFidelityScanAddon={isEnableKioskFidelityScanAddon}
          isFidelityCardScanned={isFidelityCardScanned}
          isProductsLoading={isProductsLoading}
          locale={locale}
          navigateToProductCatalogPage={navigateToProductCatalogPage}
          productCategory={productCategory}
          products={parseProductsArray(products[0])}
          retailerFidelityCardImage={retailerFidelityCardImage}
          shouldHideWishlist={!isFidelityCardScanned}
          storeType={retailerStoreType}
          userBestMatchCharacterForCurrentServiceProductCategory={
            userBestMatchCharacterForCurrentServiceProductCategory
          }
        />
      ) : (
        <ResultPageSkeleton isFromSituationTest={false} />
      )}
    </>
  );
};

export default ResultContainer;

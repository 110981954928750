import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LegalTabsContainer from '@lib/tools/shared/legals/views/containers/LegalTabsContainer';
import FoodHabitsContainer from '@lib/tools/shared/quizzes/views/containers/FoodHabitsContainer';
import QuizContainer from '@lib/tools/shared/quizzes/views/containers/QuizContainer';
import { FoodPairingContainer } from '@lib/tools/shared/recipes/views/containers/FoodPairingContainer';
import { PAGES } from '@lib/tools/views/urls';
import ErrorPageNotFound from '@lib/tools/views/web/components/ErrorPageNotFound';

import HomePageContainer from 'containers/HomeContainer';
import LoginContainer from 'containers/KioskLogin';
import KioskContainer from 'containers/ProductCatalogContainer';
import ProductPageContainer from 'containers/ProductContainer';
import ResultContainer from 'containers/ResultContainer';
import SituationalResultContainer from 'containers/SituationalResultContainer';
import WelcomePageContainer from 'containers/WelcomePageContainer';
import { AnonRoutes, KioskCatchAll, KioskRoutes } from 'routes/AccessKiosk';

const Router: FC = () => {
  const RouteGroup = (c, ...paths) => {
    return paths.map(p => <Route key={p} Component={c} path={p} />);
  };

  const kioskBasename = '/:lang/:retailer/loc/:location/p/:product';

  const retailerUrl = url => kioskBasename + url;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route Component={AnonRoutes}>
          <Route key="urlLogin" Component={LoginContainer} path={PAGES.vinhood.kioskLogin} />
        </Route>

        <Route path={kioskBasename}>
          <Route Component={KioskRoutes}>
            <Route key="urlWelcome" Component={WelcomePageContainer} path={`${retailerUrl(PAGES.vinhood.welcome)}`} />

            <Route key="urlHome" Component={HomePageContainer} path={retailerUrl(PAGES.vinhood.home)} />

            {RouteGroup(QuizContainer, retailerUrl(`/quiz/:quizType/`))}

            <Route key="urlResult" element={<ResultContainer />} path={retailerUrl(PAGES.vinhood.quizResults.result)} />

            <Route
              key="urlSituationalResult"
              element={<SituationalResultContainer />}
              path={retailerUrl(PAGES.vinhood.quizResults.situational)}
            />

            <Route key="urlCommonCatalog" Component={KioskContainer} path={retailerUrl(PAGES.vinhood.catalog)} />

            <Route key="urlProduct" Component={ProductPageContainer} path={retailerUrl(PAGES.vinhood.product)} />

            <Route
              key="urlCommonFoodHabits"
              Component={FoodHabitsContainer}
              path={retailerUrl(PAGES.vinhood.quiz.chooseTaste)}
            />

            <Route
              key="urlFoodPairing"
              Component={FoodPairingContainer}
              path={retailerUrl(PAGES.vinhood.recipeSearch)}
            />

            <Route Component={KioskCatchAll}>
              <Route key="urlCatchAllToHome" Component={HomePageContainer} path="*" />
            </Route>
          </Route>

          {/* Common routes */}
          <Route key="urlCommonLegal" Component={LegalTabsContainer} path={retailerUrl(PAGES.vinhood.legal.tabs)} />

          <Route key="urlErrorNotFound" Component={ErrorPageNotFound} path={retailerUrl(PAGES.error.notFound)} />
        </Route>

        <Route Component={KioskCatchAll}>
          <Route key="urlCatchAllToLogin" Component={LoginContainer} path="*" />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { ENV_MEDIA_DOMAIN } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { PAGES } from '@lib/tools/views/urls';

import { WelcomePage } from '@components/web/src/templates/Start&HomePages/Kiosk/WelcomePage/WelcomePage';

const WelcomePageContainer: React.FC = () => {
  const navigate = useNavigate();
  const { productCategory } = useApp();
  const { customStyle } = useRetailerLocation();
  const retailerMediaAvailable: string[] = customStyle[productCategory]?.welcome || [];
  const { locale } = useApp();
  const navigateToHomePage = () => navigate(prependBasename(PAGES.vinhood.home));

  const fallbackMedias = {
    beer: [`${ENV_MEDIA_DOMAIN}/media/kiosk_custom_backgrounds/${locale}-beer-kiosk.mp4`],
    coffee: [`${ENV_MEDIA_DOMAIN}/media/kiosk_custom_backgrounds/${locale}-coffee-kiosk.mp4`],
    wine: [`${ENV_MEDIA_DOMAIN}/media/kiosk_custom_backgrounds/${locale}-wine-kiosk.mp4`],
  };

  const media =
    retailerMediaAvailable && retailerMediaAvailable[locale] && retailerMediaAvailable[locale].length > 0
      ? retailerMediaAvailable[locale]
      : fallbackMedias[productCategory];

  return <WelcomePage handlePushButtonClick={navigateToHomePage} mediaList={media} productCategory={productCategory} />;
};

export default WelcomePageContainer;
